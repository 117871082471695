<template>
  <section class="instrucsec">
    <div class="container info">
      <div class="row instrucitons">
        <div class="col-md-6">
          <h2 class="text-end mb-4">متطلبات إنشاء عقد نكاح إلكتروني</h2>
          <ul class="custom-ul">
            <li>
              توفير بيانات أطراف العقد (الزوج – الزوجة - ولي الزوجة -أو وكلائهم
              - الشاهدين )
            </li>
            <li>وجود فحص طبي للمواطنين فقط</li>
            <li>وجود الوثائق الداعمة حسب متطلبات النموذج الإلكتروني.</li>
            <li>
              وجود صك الطلاق الإلكترونية في حال كانت الزوجة مطلقة، أو توفير
              نسخة من صك الطلاق الورقي.
            </li>
            <li>
              اذا كانت المرأة متوفى عنها زوجها يجب توفير رقم توثيق ورثة المتوفى
              إذا كان إلكترونياً، أو توفير نسخة من صك حصر الورثة في حال كان
              ورقي.
            </li>
            <li>
              جميع أطراف العقد والشهود يكونوا أعلى من 18 سنة وفي حال كان الزوج أو
              الزوجة أقل من 18 سنة يتطلب صك الموافقة على الزواج المبكر صادر من
              المحكمة المختصة.
            </li>
            <li>وجود رقم جوال موثق في (أبشر) لجميع أطراف العقد.</li>
            <li>
              ولي المرأة يجب أن يكون وفق تسلسل الولاية الشرعية للمرأة وإرفاق ما
              يثبت انتقال الولاية من الأب إلى غيره.
            </li>
            <li>
              وكالة سارية المفعول في حال كون مقدم الطلب وكيل، أو إرفاق الوكالة
              الخارجية بعد تصديقها من وزارة الخارجية ووزارة العدل
            </li>
            <li>
              إذا كانت الوثائق صادرة من خارج المملكة فتصدق من وزارة الخارجية
              ووزارة العدل .
            </li>
          </ul>
        </div>
        <!-- <div class="col-md-6 test">
          <h2 class="text-end mb-4">شروط عقد النكاح</h2>
          <ul class="custom-ul">
            <li>
              أن يكون جميع أطراف العقد سعوديين أو مقيمين بشرط تجديد الإقامة
            </li>
            <li>لابد من وجود الولي الشرعي</li>
            <li>لابد من توفر الفحص الطبي</li>
            <li>لابد من وجود صك الطلاق للمرأة المطلقة</li>
            <li>في حال وفاة الولي الشرعي (الأب) فيجب توفر صك حصر الورثة</li>
          </ul>
        </div> -->
      </div>
    </div>
    <div class="contactBak">
      <h4 class="contactH ">
        بإمكانك التواصل معنا ونحن نتولى عنك التسجيل بكل يسر عبر الواتساب
      </h4>
      <div class='contactH'>
        <a target="_blank" class='contactH' href="https://wa.me/+966509880111" 
          >تواصل معنا</a
        >
      </div>
    </div>
    <div class="summery py-4 d-flex align-items-center justify-content-center">
      <ul class="w-75 d-flex justify-content-between">
        <li>
          <div class="iconCheck mx-1">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <!-- Font Awesome Pro 6.0.0-alpha2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
              <path
                d="M475.312 123.312L203.312 395.312C200.188 398.438 196.094 400 192 400S183.812 398.438 180.688 395.312L36.688 251.312C30.438 245.062 30.438 234.937 36.688 228.688S53.063 222.438 59.312 228.688L192 361.375L452.687 100.688C458.937 94.438 469.063 94.438 475.312 100.688S481.562 117.062 475.312 123.312Z"
              />
            </svg>
          </div>
          سرعه بالإنجاز
        </li>
        <li>
          <div class="iconCheck mx-1">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <!-- Font Awesome Pro 6.0.0-alpha2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
              <path
                d="M475.312 123.312L203.312 395.312C200.188 398.438 196.094 400 192 400S183.812 398.438 180.688 395.312L36.688 251.312C30.438 245.062 30.438 234.937 36.688 228.688S53.063 222.438 59.312 228.688L192 361.375L452.687 100.688C458.937 94.438 469.063 94.438 475.312 100.688S481.562 117.062 475.312 123.312Z"
              />
            </svg>
          </div>
          دقه بالمواعيد
        </li>
        <li>
          <div class="iconCheck mx-1">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <!-- Font Awesome Pro 6.0.0-alpha2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
              <path
                d="M475.312 123.312L203.312 395.312C200.188 398.438 196.094 400 192 400S183.812 398.438 180.688 395.312L36.688 251.312C30.438 245.062 30.438 234.937 36.688 228.688S53.063 222.438 59.312 228.688L192 361.375L452.687 100.688C458.937 94.438 469.063 94.438 475.312 100.688S481.562 117.062 475.312 123.312Z"
              />
            </svg>
          </div>
          نسعد بخدمتكم
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.instrucsec {
  overflow: hidden;
}
.contactH {
  margin: 3rem;
  text-align: start;
}
 a.contactH {
      padding: .5rem 1rem;
      text-decoration: none;
      background: var(--primary-color);
      color: white;
      font-weight: bold;
      outline: none;
      border: none;
      transition: all 0.2s ease-in-out;
      width: 30%;
      border-radius: 7px;
      &:hover {
        background: #f39c12;
        color: #2c3e50;
      }
    }
@media only screen and (max-width: 768px) {
  .contactH {
    text-align: center;
  }

  .instrucitons {
    h2 {
      width: 80%;
      padding: 0 1rem;
    }
    .custom-ul {
      width: 80%;
      margin: 0 auto;
    }
  }
  .test {
    margin-top: 2rem;
  }
  .summery ul {
    flex-direction: column !important;
  }
}
.summery li {
  display: flex !important;
  font-size: 1.4rem;
}
.summery {
  margin: 0 !important;
  background: #56c297;
}
.summery ul {
  list-style: none;
  margin: 0 !important;
  color: var(--primary-dark);
}
.info {
  padding: 3rem 0;
  /* background: #F7FBFB; */
  border-top: 1px #ccc solid;
}
.custom-ul {
  padding: 0;
  text-align: start;
}
.custom-ul li {
  margin-bottom: 10px;
}
.iconCheck {
  width: 30px;
  fill: #2c3e50;
}
.contactBak{
  padding: .4rem 0;
  min-height: 20vh;
  background: #F7FBFB;
}
</style>
