<template>
  <!-- Modal gallery -->

  <!-- Section: Images -->
  <section class="gallerySec">
    <h5 class="text-center">
      بحسب النظام الجديد لوزارة العدل أصبح تسجيل بيانات عقد النكاح بطريقة
      إلكترونية بدون حضور المستفيد للمحكمة
      <span class="d-block">
        وذلك بالدخول على الروابط التالية واتباع التعليمات
      </span>
    </h5>
    <div class="row px-4">
      <div class="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0">
        <div class="d-flex flex-column">
          <div
            class="bg-image hover-overlay ripple shadow-1-strong rounded"
            data-ripple-color="light"
          >
            <img src="../../../assets/imgs/documents.jpeg" />
          </div>
        </div>

        <a
          href="https://drive.google.com/file/d/1pwPp5Zn2elFGsNi0r12xBPLhR_qXYrsi/view "
          class="actoinBtn d-flex py-2 px-4 my-2 mx-auto"
        >
          <h6>الدليل الإرشادي لإنشاء عقد زواج</h6>

          <div class="arrowLeft">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <!-- Font Awesome Pro 6.0.0-alpha2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
              <path
                d="M448.004 256C448.004 264.844 440.848 272 432.004 272H68.174L203.848 421.25C209.785 427.781 209.301 437.906 202.77 443.844C199.707 446.625 195.848 448 192.004 448C187.66 448 183.316 446.25 180.16 442.75L20.16 266.75C14.613 260.656 14.613 251.344 20.16 245.25L180.16 69.25C186.129 62.688 196.254 62.25 202.77 68.156C209.301 74.094 209.785 84.219 203.848 90.75L68.174 240H432.004C440.848 240 448.004 247.156 448.004 256Z"
              />
            </svg>
          </div>
        </a>
      </div>
      <!-- ................. -->
      <div class="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0">
        <div class="d-flex flex-column nazSvg">
          <div
            class="bg-image hover-overlay ripple shadow-1-strong rounded"
            data-ripple-color="light"
          >
            <!-- <img src="../../../assets/imgs/naz.svg /> -->
            <img src="../../../assets/imgs/naz.svg" />
          </div>
        </div>

        <a
          href="https://inhaatportal.moj.gov.sa/HomePage.aspx"
          class="actoinBtn d-flex py-2 px-4 my-2 mx-auto"
        >
          <h6>لتقديم عقد زواج جديد</h6>

          <div class="arrowLeft">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <!-- Font Awesome Pro 6.0.0-alpha2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
              <path
                d="M448.004 256C448.004 264.844 440.848 272 432.004 272H68.174L203.848 421.25C209.785 427.781 209.301 437.906 202.77 443.844C199.707 446.625 195.848 448 192.004 448C187.66 448 183.316 446.25 180.16 442.75L20.16 266.75C14.613 260.656 14.613 251.344 20.16 245.25L180.16 69.25C186.129 62.688 196.254 62.25 202.77 68.156C209.301 74.094 209.785 84.219 203.848 90.75L68.174 240H432.004C440.848 240 448.004 247.156 448.004 256Z"
              />
            </svg>
          </div>
        </a>
      </div>
      <!-- .................. -->

      <div class="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0">
        <div class="d-flex flex-column nazSvg">
          <div
            class="bg-image hover-overlay ripple shadow-1-strong rounded"
            data-ripple-color="light"
          >
            <img src="../../../assets/imgs/naz.svg" />
          </div>
        </div>

        <a
          href="https://inhaatportal.moj.gov.sa/PartiesVerificationOnRequest.aspx"
          class="actoinBtn d-flex py-2 px-4 my-2 mx-auto"
        >
          <h6>للمصادقة على طلب سابق</h6>

          <div class="arrowLeft">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <!-- Font Awesome Pro 6.0.0-alpha2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
              <path
                d="M448.004 256C448.004 264.844 440.848 272 432.004 272H68.174L203.848 421.25C209.785 427.781 209.301 437.906 202.77 443.844C199.707 446.625 195.848 448 192.004 448C187.66 448 183.316 446.25 180.16 442.75L20.16 266.75C14.613 260.656 14.613 251.344 20.16 245.25L180.16 69.25C186.129 62.688 196.254 62.25 202.77 68.156C209.301 74.094 209.785 84.219 203.848 90.75L68.174 240H432.004C440.848 240 448.004 247.156 448.004 256Z"
              />
            </svg>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0">
        <div class="d-flex flex-column nazSvg">
          <div
            class="bg-image hover-overlay ripple shadow-1-strong rounded"
            data-ripple-color="light"
          >
            <img src="../../../assets/imgs/عقد.jpg" />
            <!-- <img
              src="../../../assets/imgs/كيفية-إصدار-وكالة-من-مؤسسة-لفرد-عبر-بوابة-ناجز.jpg"
            /> -->
          </div>
        </div>

        <a
          href="https://new.najiz.sa/applications/social-cases/marriage-contracts"
          class="actoinBtn d-flex py-2 px-4 my-2 mx-auto"
        >
          <h6>للتحقق من عقد زواج الكتروني</h6>

          <div class="arrowLeft">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <!-- Font Awesome Pro 6.0.0-alpha2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
              <path
                d="M448.004 256C448.004 264.844 440.848 272 432.004 272H68.174L203.848 421.25C209.785 427.781 209.301 437.906 202.77 443.844C199.707 446.625 195.848 448 192.004 448C187.66 448 183.316 446.25 180.16 442.75L20.16 266.75C14.613 260.656 14.613 251.344 20.16 245.25L180.16 69.25C186.129 62.688 196.254 62.25 202.77 68.156C209.301 74.094 209.785 84.219 203.848 90.75L68.174 240H432.004C440.848 240 448.004 247.156 448.004 256Z"
              />
            </svg>
          </div>
        </a>
      </div>
      <!-- 0000000000000000000000 -->
      <div class="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0">
        <div class="d-flex flex-column nazSvg">
          <div
            class="bg-image hover-overlay ripple shadow-1-strong rounded"
            data-ripple-color="light"
          >
            <img src="../../../assets/imgs/صحة.svg" />
          </div>
        </div>

        <a
          href="https://premarriage.seha.sa/"
          class="actoinBtn d-flex py-2 px-4 my-2 mx-auto"
        >
          <h6>للاستعلام عن فحص طبي</h6>

          <div class="arrowLeft">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <!-- Font Awesome Pro 6.0.0-alpha2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
              <path
                d="M448.004 256C448.004 264.844 440.848 272 432.004 272H68.174L203.848 421.25C209.785 427.781 209.301 437.906 202.77 443.844C199.707 446.625 195.848 448 192.004 448C187.66 448 183.316 446.25 180.16 442.75L20.16 266.75C14.613 260.656 14.613 251.344 20.16 245.25L180.16 69.25C186.129 62.688 196.254 62.25 202.77 68.156C209.301 74.094 209.785 84.219 203.848 90.75L68.174 240H432.004C440.848 240 448.004 247.156 448.004 256Z"
              />
            </svg>
          </div>
        </a>
      </div>
      <!-- ......... -->
      <div class="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0">
        <div class="d-flex flex-column nazSvg">
          <div
            class="bg-image hover-overlay ripple shadow-1-strong rounded"
            data-ripple-color="light"
          >
            <img src="../../../assets/imgs/3اخرى.png" />
          </div>
        </div>

        <a
          href="https://inhaatportal.moj.gov.sa/tabsnew.aspx"
          class="actoinBtn d-flex py-2 px-4 my-2 mx-auto"
        >
          <h6>خدمات توثيق أخرى</h6>

          <div class="arrowLeft">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <!-- Font Awesome Pro 6.0.0-alpha2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
              <path
                d="M448.004 256C448.004 264.844 440.848 272 432.004 272H68.174L203.848 421.25C209.785 427.781 209.301 437.906 202.77 443.844C199.707 446.625 195.848 448 192.004 448C187.66 448 183.316 446.25 180.16 442.75L20.16 266.75C14.613 260.656 14.613 251.344 20.16 245.25L180.16 69.25C186.129 62.688 196.254 62.25 202.77 68.156C209.301 74.094 209.785 84.219 203.848 90.75L68.174 240H432.004C440.848 240 448.004 247.156 448.004 256Z"
              />
            </svg>
          </div>
        </a>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.gallerySec {
  overflow: hidden;
  margin-bottom: 5rem;
  // margin-top: 2rem;
  h5 {
    margin: 2rem 0;

    color: var(--secondary-color);
  }
  .bg-image {
    img {
      height: 300px !important;
      width: 400px;
      object-fit: cover;
    }
  }

  a {
    cursor: pointer;
    text-decoration: none;
    width: 400px;
    position: relative;
    font-weight: bold !important;

    color: #2c3e50;
    border: 2px solid var(--primary-color) !important;
    border: none;
    outline: none;
    border-radius: 2rem;
    justify-content: center;
    align-content: center;
    text-align: center;
    transition: 0.3s all ease-in-out;

    &:hover {
      background: var(--primary-color);
      color: white;
    }
    h6 {
      font-size: 1.2rem;
      text-align: start;
    }
    .arrowLeft {
      background: var(--primary-color);
      padding: 10px;
      border-radius: 50% 50%;

      position: absolute;
      left: 0;
      top: 0;
      svg {
        fill: white;
        width: 27px;
        height: 27px;
      }
    }
  }
}

img {
  border-radius: 8px;
}

@media only screen and (max-width: 768px) {
  .arrowLeft {
    background: var(--primary-color);
    padding: 8px !important;
    border-radius: 50% 50%;

    position: absolute;
    left: 0;
    top: 0;
    svg {
      fill: white;
      width: 27px;
      height: 27px;
    }
  }
  a {
    width: 320px !important;
  }
  h6 {
    font-size: 1rem !important;
    text-align: start;
  }
  .bg-image {
    img {
      width: 320px !important;
    }
  }

  .nazSvg img {
    object-fit: fill !important;
  }
}
</style>
